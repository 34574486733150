






































































































import Api from '@/includes/logic/Api'
import { InputSetups } from '@/mixins/input-setups'
import Quizes from '@/views/posts-planner/components/Quizes.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { errorNotification, successNotification } from '@/includes/NotificationService'
import { BRAND_TAG } from '@/includes/constants'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import DrawerWidthMixin from 'piramis-base-components/src/logic/helpers/DrawerWidthMixin'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { FileType } from 'piramis-base-components/src/components/File/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { PollPost, IPostMessage, QuizPost } from "piramis-base-components/src/shared/modules/posting/types";

import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'

@Component({
  components: {
    Quizes,
    PageTitle,
    NotAvailableOptionsOverlay,
    EmptyData
  },
  data() {
    return {
      FileType
    }
  }
})
export default class DraftsList extends Mixins(InputSetups, UseFields, DrawerWidthMixin) {
  BRAND_TAG = BRAND_TAG

  selectedDraft: any = {}

  sidebarRerenderKey = 0

  get boardTokenIdCode() {
    return this.$store.getters.currentBoardKey
  }

  isActive = false

  draftPosts: Array<{ key: Record<string, any>, message: IPostMessage | PollPost | QuizPost }> = []

  isMounted = false

  get boardTokenCode() {
    return this.$store.state.postsPlanner.currentBoard.board.token_code
  }

  @Watch('$refs.sidebar.value')
  onSidebarExpand(value: boolean) {
    if (!value) {
      this.selectedDraft = null
      this.sidebarRerenderKey += 1
    }
  }

  draftMessageText(draft:{ key: Record<string, any>, message: IPostMessage | PollPost | QuizPost }) {
    if (draft.message.type === 'Post') {
      const firstMessageText = draft.message.variants[0].text

      return firstMessageText.length > 50 ? firstMessageText.slice(0, 50) + "..." : firstMessageText
    }

    return draft.message.text
  }

  deleteDraftClickHandler({ token_code, token_id }: { token_code: string, token_id: string }) {
    const post_key = token_id + '.' + token_code
    this.$confirm({
      title: this.$t('draft_confirm_remove').toString(),
      content: this.$t('draft_confirm_remove_text').toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: async () => {
        this.$baseTemplate.loader.open()
        try {
          await Api.deleteSavedPost('tg', { board_key: `${ this.boardTokenIdCode }`, post_key: post_key })
          await this.getSavedPosts(this.boardTokenIdCode)
          successNotification()
        } catch (error) {
          errorNotification(error)
        } finally {
          this.$baseTemplate.loader.close()
        }
      },
    })
  }

  modelSetter(args: FieldData): FieldData {
    args.setter = (value: any): any => {
      args.model[args.key] = value
    }
    return args
  }

  handlePreviewButtonSelect(post: any) {
    this.selectedDraft = post.message
    this.isActive = true
  }

  createPostFromDraft(draft: { key: Record<string, any>, message: IPostMessage | PollPost | QuizPost }) {
    this.$router.push({
      name: 'post',
      params: {
        [EntityTypes.BOT_ID]: this.$route.params[EntityTypes.BOT_ID],
        actionType: 'new'
      },
      query: {
        draft: draft.key.token_id
      }
    })
  }

  getSavedPosts(boardTokenIdCode: string): Promise<void> {
    this.$baseTemplate.loader.open()
    return Api.getSavedPosts('tg', { board: boardTokenIdCode })
      .then(({ data }) => {
        this.draftPosts = (data.posts as Array<{ key: Record<string, any>, message: IPostMessage | PollPost | QuizPost }>).sort((a, b) => a.key.token_id - b.key.token_id)
      }).catch(errorNotification)
      .finally(() => this.$baseTemplate.loader.close())
  }

  mounted(): void {
    if (this.boardTokenCode !== 'default') {
      this.getSavedPosts(this.boardTokenIdCode)
    }

    this.isMounted = true
  }
}
